import React from "react";
import cloudImg from '../../assets/cloud-2.svg';
import { CloudImageEl, EmailEl, TextEl, Container } from './styles';

export const ContactUs = () => {
    return (
        <Container>
            <div>
                <TextEl>E-mail us on</TextEl>
                <EmailEl href = "mailto:info@draftcloud.dev">info@draftcloud.dev</EmailEl>
            </div>
            <div>
                <CloudImageEl alt={'see you soon'} src={cloudImg} />
            </div>
        </Container>
    );
}
