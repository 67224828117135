import styled from 'styled-components';

export const CloudImageEl = styled.img`
    width: 164px;
    height: 86px;
    margin-left: 60px;
    
    @media (max-width: 1020px) { 
        display: none;
    }
`;

export const TextEl = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    line-height: 30px;
    color: #1E1E1E;
`;

export const EmailEl = styled.a`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    color: #1E1E1E;
`;

export const Container = styled.div`
    display: flex;
    align-items: flex-end;
    margin-top:auto;
`;
