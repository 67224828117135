import styled from 'styled-components';

export const CloudImageEl = styled.img`
    width: 212px;
    height: 100px;
    margin: 0 auto;
    
    @media (max-width: 1020px) { 
        display: none;
    }
`;

export const CloudImageElMobile = styled.img`
    width: 335px;
    height: 423px;
    
    display: none;
    
    @media (max-width: 1020px) { 
        display: inline-block;
        margin-bottom: 35px;
        margin-top: 60px;
    }
    
    @media (max-width: 370px) { 
        width: 290px;
        margin-bottom: 0;
    }
`;

export const TextEl = styled.h1`
    font-family: 'Comfortaa', sans-serif;
    font-size: 50px;
    line-height: 65px;
    color: #1E1E1E;
    margin-top: 100px;
    
    
    @media (max-width: 1020px) { 
        font-size: 30px;
        line-height: 40px;
        position: absolute;
        margin: 0;
        top: 100px;
    }
    
    @media (max-width: 370px) { 
        font-size: 24px;
        line-height: 40px;
        top: 115px;
    }

`;

