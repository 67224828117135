import React from 'react';
import Logo from "../Logo/Logo";
import { SeeYouSoonBlock } from '../SeeYouSoonBlock/SeeYouSoonBlock';
import { TextBlock } from '../TextBlock/TextBlock';
import { ContactUs } from '../ContactUs/ContactUs';
import { Container, Header, RightBlock } from './styles';

function Main() {
    return (
        <div>
            <Header>
                <Logo />
            </Header>
            <Container>
                <SeeYouSoonBlock />
                <RightBlock>
                    <TextBlock />
                    <ContactUs />
                </RightBlock>
            </Container>
        </div>
    );
}

export default Main;
