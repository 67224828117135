import styled from 'styled-components';

export const SeeYouSoonBlockEl = styled.img`
    width: 595px;
    height: 655px;
    
    @media (max-width: 1020px) { 
        display: none;
    }
`;
