import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 80%;
    justify-content: space-around;
    align-content: center;
    margin: 20px auto;
    
    @media (max-width: 1020px) { 
         padding-top: 10px;
    }
`;


export const Header = styled.header`
    padding-top: 40px;
    padding-left: 75px;
    
    @media (max-width: 1020px) { 
         padding-left: 35px;
         padding-top: 15px;

    }
`;

export const RightBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: column nowrap;
    margin-top: -80px;
    position: relative;
    
    
    @media (max-width: 1020px) { 
        text-align: center;
        align-items: center;
    }

`;
