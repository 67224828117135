import React from "react";
import { TextEl, CloudImageEl, CloudImageElMobile } from "./styles";
import cloudImg from '../../assets/cloud-1.svg';
import cloudImgMobile from '../../assets/cloud-3.svg';

export const TextBlock = () => {
    return (
        <>
            <CloudImageEl src={cloudImg} />
            <TextEl>We are creating <br /> something cool <br /> for you!</TextEl>
            <CloudImageElMobile src={cloudImgMobile} />
        </>
    );
}
