import styled from 'styled-components';

export const LogoEl = styled.img`
    width: 199px;
    height: 126px;

    @media (max-width: 1020px) { 
        width: 123px;
        height: 77px;
    }    

`;
